<template>
  <div>
    <el-card>
      <el-form ref="form" :inline="true">
        <el-form-item label="类型">
          <el-select
            v-model="queryForm.type"
            placeholder="请选择位类型分类"
            clearable
          >
            <el-option
              :label="item.value"
              :value="item.key"
              v-for="item in typeArr"
              :key="item.key"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态">
          <el-select
            v-model="queryForm.display"
            placeholder="请选择显示状态"
            clearable
          >
            <el-option
              :label="item.value"
              :value="item.key"
              v-for="item in displayArr"
              :key="item.key"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="时间">
          <el-date-picker
            type="datetimerange"
            v-model="times"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="toQuery" icon="el-icon-search"
            >查询</el-button
          >
        </el-form-item>
        <el-form-item class="generate">
          <el-button type="primary" @click="toAddAdvert"
            >添加广告</el-button
          >
        </el-form-item>
      </el-form>
      <el-table
        :data="advertList"
        stripe
        class="table"
        ref="userTable.multipleSelection"
        header-cell-class-name="table-header"
        border
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>

        <el-table-column
          prop="id"
          label="ID"
          width="55"
          align="center"
        ></el-table-column>
       
        <el-table-column label="标题" prop="title" align="center"></el-table-column>
        
         <el-table-column label="分类" prop="type_ch" align="center">
        </el-table-column>

        <el-table-column label="缩略图" align="center">
          <template slot-scope="scope">
            <div @click="openThumb(scope.row.thumb_url)" class="thumb_box" v-if="scope.row.thumb">
                <img :src="scope.row.thumb_url" alt="">
            </div>
          </template>
        </el-table-column>
        <el-table-column label="状态" prop="display" align="center">
          <template slot-scope="scope">
            <el-tag
              type="success"
              v-if="scope.row.display == 1"
            >
              显示
            </el-tag>
            <el-tag type="danger" v-else
              >隐藏
            </el-tag>
          </template>
        </el-table-column>

         <el-table-column label="展示时间" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.play_time_start">
              <div>
                {{$moment(scope.row.play_time_start * 1000).format("YYYY-MM-DD HH:mm:ss")}}
              </div>
              <div>至</div>
              <div>
                {{$moment(scope.row.play_time_end * 1000).format("YYYY-MM-DD HH:mm:ss")}}
              </div>
            </div>
            <div v-else>无</div>
          </template>
        </el-table-column>

        <el-table-column label="创建时间" align="center">
          <template slot-scope="scope">
            {{
                $moment(scope.row.create_time * 1000).format(
                  "YYYY-MM-DD HH:mm:ss"
                )
              }}
          </template>
        </el-table-column>
       
   
        <el-table-column label="操作" align="center" >
          <template slot-scope="scope">
            <el-button
              type="primary"
              icon="el-icon-edit"
              circle
              @click="toEditAdvert(scope.row)"
              size="small"
            ></el-button>
            <el-button
              type="danger"
              icon="el-icon-delete"
              circle
              @click="removeAdvertisement(scope.row.id)"
              size="small"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>

       <!-- 分页区域 -->
     <div class="pagination">
       <span></span>
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryForm.page"
        :page-sizes="[3, 5, 8, 10]"
        :page-size="queryForm.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        algin:="center"
      ></el-pagination>
     </div>

    <!-- 缩略图 -->
    <el-dialog title="缩略图详情" :visible.sync="detailsDialog" :center=true width="40%">
      <div style="width:100%;height:100%">
        <img style="width:100%;height:100%" :src="detailsImg" alt="">
      </div>
    </el-dialog>

    <!-- 添加广告 -->
    <el-dialog :title="isAdd?'添加广告':'编辑广告'" :visible.sync="addDialog" :center=true width="40%" @close="closed" :close-on-click-modal=false>
      <el-form :model="addForm"  ref="addForm">
        <el-form-item el-form-item label="分类" label-width="100px">
          <el-select
            v-model="addForm.type"
            placeholder="请选择分类"
            clearable
          >
            <el-option
              :label="item.value"
              :value="item.key"
              v-for="item in typeArr"
              :key="item.key"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item el-form-item label="广告标题" label-width="100px">
            <el-input v-model="addForm.title" placeholder="请输入广告标题"></el-input>
        </el-form-item>
        <el-form-item el-form-item label="显示状态" label-width="100px">
          <el-select
            v-model="addForm.display"
            placeholder="请选择状态"
            clearable
          >
            <el-option
              :label="item.value"
              :value="item.key"
              v-for="item in displayArr"
              :key="item.key"
            ></el-option>
          </el-select>
        </el-form-item>
 
        <el-form-item el-form-item label="url" label-width="100px">
            <el-input v-model="addForm.url" placeholder="请输入跳转地址"></el-input>
        </el-form-item>
        <el-form-item el-form-item label="缩略图" label-width="100px">
            <el-upload
              class="avatar-uploader"
              action="123"
              :show-file-list="false"
              accept="image/*"
              :before-upload="beforeThumbUpload"
              style="width:260px;height:260px"
            >
                <img
                  v-if="thumbSrc"
                  style="width: 100%"
                  :src="thumbSrc"
                  class="avatar"
                />
                <i
                v-else
                class="el-icon-plus avatar-uploader-icon"
              ></i>
            </el-upload>
        </el-form-item>

       <div v-if="addForm.type=='korean_start_advert'||addForm.type=='japanese_start_advert'">
          <el-form-item el-form-item label="缩略图宽" label-width="100px">
            <el-input  oninput="value=value.replace(/[^\d]/g,'')"  v-model="addForm.width" placeholder="请输入缩略图宽"></el-input>
            <div class="tips">在app展示宽度应为375-750之间</div>
          </el-form-item>

          <el-form-item el-form-item label="缩略图高" label-width="100px">
              <el-input  oninput="value=value.replace(/[^\d]/g,'')" v-model="addForm.height" placeholder="请输入缩略图高"></el-input>
          </el-form-item>
       </div>

       <div v-if="addForm.type=='japanese_start_page'||addForm.type=='korean_start_page'">
         <el-form-item el-form-item label="展示日期" label-width="100px">
            <el-date-picker
              type="datetimerange"
              v-model="addFormTimes"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="timestamp"
            >
            </el-date-picker>
        </el-form-item>
       </div>
        <el-form-item label-width=40% style="margin-top: 20px;">
           <el-button type="primary" v-if="isAdd" @click="addNow" >立即添加</el-button>
           <el-button type="primary" v-else @click="editNow" >立即修改</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    </el-card>
  </div>
</template>

<script>
var COS = require("cos-js-sdk-v5");
import $moment from "moment";
import {
  AdvertList,
  AdvertTypeArr,
  AdvertDisplay,
  AdvertDel,
  AdvertAdd,
  AdvertCosToken,
  AdvertEdit,
} from "@/api/advertisingList.js";
export default {
  data() {
    return {
      queryForm: {
        type: null,
        display: null,
        page: 1,
        limit: 10,
        start_time: null,
        end_time: null,
      },
      advertList: [],
      typeArr: [],
      displayArr: [
        { key: 1, value: "显示" },
        { key: 0, value: "隐藏" },
      ],
      total: 0,
      addDialog: false,
      times: null,
      addForm: {
        type: "",
        title: "",
        url: "",
        thumb: "",
        display: "",
        width: "",
        height: "",
        play_time_start: "",
        play_time_end: "",
      },
      addFormTimes: null,
      thumbSrc: "",
      detailsImg: "",
      detailsDialog: false,
      isAdd: true,
    };
  },
  created() {
    this.getAdvertTypeArr();
    this.getAdvertList();
  },

  methods: {
    openThumb(src) {
      this.detailsDialog = true;
      this.detailsImg = src;
    },
    //   广告列表
    getAdvertList() {
      AdvertList(this.queryForm).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.total = res.data.total;
        this.advertList = res.data.data;
      });
    },

    // 获取获取类型分类
    getAdvertTypeArr() {
      AdvertTypeArr().then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.typeArr = res.data;
      });
    },
    // //  修改状态
    // editState(id, index) {
    //   let data = {
    //     id: id,
    //     display: index,
    //   };
    //   AdvertDisplay(data).then((res) => {
    //     if (res.code !== 1) {
    //       this.$message.error(res.msg);
    //       return;
    //     }
    //     this.$message.success("修改状态成功");
    //     this.getAdvertList();
    //   });
    // },

    // 删除广告
    async removeAdvertisement(id) {
      const result = await this.$confirm("是否要删除该广告?", "删除提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      if (result !== "confirm") return this.$message.info("已经取消删除");
      AdvertDel({ id: id }).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("删除广告成功");
        this.getAdvertList();
      });
    },

    // 点击添加广告按钮
    toAddAdvert() {
      this.isAdd = true;
      this.addDialog = true;
    },

    // 上传缩略图
    beforeThumbUpload(file) {
      const isSize = file.size / 1024 / 1024 < 10;
      if (!isSize) {
        this.$message.error("上传缩略图不能超过 10MB!");
        return;
      }
      let that = this;
      AdvertCosToken({ suffix: file.name }).then((res) => {
        var cos = new COS({
          getAuthorization: function (options, callback) {
            callback({
              TmpSecretId: res.data.credentials.tmpSecretId,
              TmpSecretKey: res.data.credentials.tmpSecretKey,
              XCosSecurityToken: res.data.credentials.sessionToken,
              StartTime: res.data.startTime,
              ExpiredTime: res.data.expiredTime,
              expiration: res.data.expiration,
              requestId: res.data.requestId,
            });
          },
        });
        cos.putObject(
          {
            Bucket: res.data.Bucket,
            Region: res.data.Region,
            Key: res.data.key,
            // ContentType:'multipart/form-data',
            Body: file, // 上传文件对象
            onProgress: function (progressData) {},
          },
          function (err, data) {
            if (data) {
              that.addForm.thumb = res.data.key;
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = (e) => {
                that.thumbSrc = e.target["result"]; // 获取文件装换后的Base64位文件
                that.$forceUpdate();
              };
            }
          }
        );
      });
    },

    // 添加任务
    addNow() {
      if (this.addForm.type == "") {
        this.$message.error("请选择类型");
        return;
      } else if (this.addForm.title == "") {
        this.$message.error("请输入广告标题");
        return;
      } else if (this.addForm.display === "") {
        this.$message.error("请选择显示状态");
        return;
      } else if (this.addForm.thumb == "") {
        this.$message.error("请上传缩略图");
        return;
      } else if (
        (this.addForm.type == "japanese_start_advert" &&
          this.addForm.width == "") ||
        (this.addForm.type == "korean_start_advert" && this.addForm.width == "")
      ) {
        this.$message.error("请输入缩略图在app端展示的宽度");
        return;
      } else if (
        (this.addForm.type == "japanese_start_advert" &&
          this.addForm.height == "") ||
        (this.addForm.type == "korean_start_advert" &&
          this.addForm.height == "")
      ) {
        this.$message.error("请输入缩略图在app端展示的高度");
        return;
      } else if (
        (this.addForm.type == "japanese_start_page" &&
          this.addFormTimes == null) ||
        (this.addForm.type == "korean_start_page" && this.addFormTimes == null)
      ) {
        this.$message.error("选择展示日期时间");
        return;
      }

      if (
        this.addForm.type == "japanese_start_page" ||
        this.addForm.type == "korean_start_page"
      ) {
        if (this.addFormTimes) {
          this.addForm.play_time_start = this.addFormTimes[0] / 1000;
          this.addForm.play_time_end = this.addFormTimes[1] / 1000;
        } else if (this.addFormTimes == null) {
          this.addForm.play_time_start = "";
          this.addForm.play_time_end = "";
        }
      }
      AdvertAdd(this.addForm).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("添加广告成功");
        this.addDialog = false;
        this.getAdvertList();
      });
    },
    // 点击编辑按钮
    toEditAdvert(row) {
      this.isAdd = false;
      this.addDialog = true;
      this.addForm = JSON.parse(JSON.stringify(row));
      this.thumbSrc = this.addForm.thumb_url;
      this.addFormTimes = [
        this.addForm.play_time_start * 1000,
        this.addForm.play_time_end * 1000,
      ];
      // console.log(this.addForm);
    },

    // 点击立即修改按钮
    editNow() {
      if (this.addForm.type == "") {
        this.$message.error("请选择类型");
        return;
      } else if (this.addForm.title == "") {
        this.$message.error("请输入广告标题");
        return;
      } else if (this.addForm.display === "") {
        this.$message.error("请选择显示状态");
        return;
      } else if (this.addForm.thumb == "") {
        this.$message.error("请上传缩略图");
        return;
      } else if (
        (this.addForm.type == "japanese_start_advert" &&
          this.addForm.width == "") ||
        (this.addForm.type == "korean_start_advert" && this.addForm.width == "")
      ) {
        this.$message.error("请输入缩略图在app端展示的宽度");
        return;
      } else if (
        (this.addForm.type == "japanese_start_advert" &&
          this.addForm.height == "") ||
        (this.addForm.type == "korean_start_advert" &&
          this.addForm.height == "")
      ) {
        this.$message.error("请输入缩略图在app端展示的高度");
        return;
      } else if (
        (this.addForm.type == "japanese_start_page" &&
          this.addFormTimes == null) ||
        (this.addForm.type == "korean_start_page" && this.addFormTimes == null)
      ) {
        this.$message.error("选择展示日期时间");
        return;
      }

      if (
        this.addForm.type == "japanese_start_page" ||
        this.addForm.type == "korean_start_page"
      ) {
        if (this.addFormTimes) {
          this.addForm.play_time_start = this.addFormTimes[0] / 1000;
          this.addForm.play_time_end = this.addFormTimes[1] / 1000;
        } else if (this.addFormTimes == null) {
          this.addForm.play_time_start = "";
          this.addForm.play_time_end = "";
        }
      }
      AdvertEdit(this.addForm).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("编辑广告成功");
        this.addDialog = false;
        this.getAdvertList();
      });
    },
    // 弹窗关闭事件
    closed() {
      this.addForm = {
        type: "",
        title: "",
        url: "",
        thumb: "",
        display: "",
        width: "",
        height: "",
        play_time_start: "",
        play_time_end: "",
      };
      this.thumbSrc = "";
      this.addFormTimes = null;
    },

    //  查询
    toQuery() {
      if (this.queryForm.display === "") {
        this.queryForm.display = null;
      }
      this.queryForm.page = 1;
      if (this.times) {
        this.queryForm.start_time = new Date(this.times[0]).getTime() / 1000;
        this.queryForm.end_time = new Date(this.times[1]).getTime() / 1000;
      } else if (this.times == null) {
        this.queryForm.start_time = null;
        this.queryForm.end_time = null;
      }
      this.getAdvertList();
    },
    handleSizeChange(size) {
      this.queryForm.limit = size;
      this.getAdvertList();
    },
    handleCurrentChange(page) {
      this.queryForm.page = page;
      this.getAdvertList();
    },
  },
};
</script>

<style lang="less" scoped>
.generate {
  float: right;
}
.el-tag {
  cursor: pointer;
}
::v-deep .ql-editor {
  min-height: 400px;
}
.thumb_box {
  width: 100%;
  height: 100%;
  cursor: pointer;
  img {
    width: 160px;
    height: 90px;
  }
}

.tips {
  color: red;
}

::v-deep .el-upload--text {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
